.orange {
    color: #ffc629;
}

.bgorange {
    padding: .5rem 1.1rem;
    background: #ffc629;
    border-radius: 20px;
    color: white;
}

.focus {
    border: 1px solid lightgray !important;
}

.focus:focus {
    outline: 1px solid orange !important;
}

img {
    object-fit: cover;
}