* {

  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  font-family: sans-serif;
}

html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

body {
  /* word-break: break-all; */
  position: relative;
  background: black;
}

*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

.otpText {
  color: rgb(255, 174, 87);
}

.margin {

  margin-left: 1.5rem;
  margin-top: 5rem;
  width: 25px;
}


.wy-icon-primary > path, .wy-message-bubble{
  fill: rgb(255, 174, 87) !important;
}

.wy-message-bubble{
  background-color: #f6f6f6 !important;
}

.wy-appbars,.wy-footerbar-sticky {
  z-index: 2 !important;
}

.blue {
  background: #1672e6 !important;
}

.bgremove {
  background: transparent !important;
  border: 2px solid black !important;
}

.facebook {

  width: 1.3rem;
  color: white;
  margin-right: 1rem;
}

a {

  color: #6b6b6b;
  text-decoration: none;
}


.space a {
  color: #6b6b6b;
  margin-left: 1rem;
}


.remove {

  margin: 0 !important;
  padding: 0 !important;
}

.tag1 {

  position: absolute;
  top: 30%;
  left: 20%;
  transform: translate(-50%, -50%)
}

.tag2 {

  position: absolute;
  bottom: 40%;
  left: 20%;
  transform: translate(-50%, -50%)
}

.tag3 {

  position: absolute;
  bottom: 40%;
  top: 20%;
  left: 20%;
  transform: translate(-50%, -50%)
}

.form-control {
  padding: 1.2rem 4rem !important;
  width: 350px !important;
  border: 1px solid #80808042 !important;
  border-radius: 20px !important;
  outline: none;
  position: relative;
}

.dot {

  width: 1rem !important;
  height: 1rem !important;
}



.nav-link {
  font-size: 15.3px;
  text-transform: uppercase;
}

#shopCarousel .control-dots {
  display: none !important;
}

.character {
	display: flex;
	justify-content: center !important;
	align-items: center !important;
	border: none;
	font-size: 20px;
	border-radius: 4px !important;
  border: 1px solid #DCDCDC;
	/* light theme */
	color: white;
	background-color: black;
  height: 40px;
  outline: none;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 16px;
}
:where(.vi__character--selected) {
  outline: none !important;
  border: 1px solid rgb(255, 174, 87) !important;
}
#field-0{
  width: 50px !important;
  border-bottom-left-radius: 1rem !important;
  border-top-left-radius: 1rem !important;
  text-align:right;
}

#field-5{
  width: 50px !important;
    border-bottom-right-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
}


/* -----Phone Input Start ----- */
.phoneInputFields {
	/* font-family: 'Manrope'; */
  width: 350px !important;
	/* line-height: 2rem !important; */
	outline: none;
	font-weight: 500 !important;
	text-align: left;
	font-size: 14px !important;
	padding-left: 60px !important;
	background: black !important;
	padding: 0.6rem 1rem 0.6rem 75px !important;
	border: 1px solid #dcdcdc !important;
	border-radius: 57px !important;
	height: 40px !important;
	height: auto !important;
	overflow: hidden !important;
	color: #fff !important;
}


/* -----Phone Input Start ----- */
.phoneInputFieldsContact {
	/* font-family: 'Manrope'; */
  width: 100% !important;
	/* line-height: 2rem !important; */
	outline: none;
	font-weight: 500 !important;
	text-align: left;
	font-size: 14px !important;
	padding-left: 60px !important;
	background: black !important;
	padding: 0.6rem 1rem 0.6rem 75px !important;
	border: 1px solid #dcdcdc !important;
	border-radius: 57px !important;
	height: 40px !important;
	height: auto !important;
	overflow: hidden !important;
	color: #fff !important;
}

.react-tel-input .flag-dropdown {
	top: 0.063rem !important;
	bottom: 0.063rem !important;
	left: 0.125rem !important;
	width: 3rem !important;
	background: black !important;
	border-bottom-left-radius: 57px !important;
	border-top-left-radius: 57px !important;
	border-top: none !important;
	border-bottom: none !important;
	border-left: none !important;
	border-right: none !important;
	padding-left: 24px !important;
}
.react-tel-input .country-list{
  width: 350px !important;
  left: 0rem;
  border-radius: 20px !important;
  border: 1px solid rgb(255, 174, 87) !important;
  padding:1rem 0rem;
  background-color: #000;
}

.phoneInputFields:focus {
	border: 1px solid rgb(255, 174, 87) !important;
	box-shadow: none !important;
}
.phoneInputFields:focus + .flag-dropdown {
	box-shadow: none !important;
}
.react-tel-input .selected-flag {
	padding: 0px !important;
}
.country{
text-align: start;
}
.country-name{
  color: white;
}

.react-tel-input .country-list .country:hover{
  background: linear-gradient(90deg, rgb(221, 128, 73) 0%, rgb(210, 159, 78) 100%, rgb(0, 212, 255) 100%);
}

.react-tel-input .flag {
	transform: scale(1.4);
	left: 0.2rem;
}

.react-tel-input .selected-flag .arrow {
	left: 23px !important;
}

.selected-flag::after {
	content: '';
	display: none !important;
	height: 33px;
	width: 1px;
	position: absolute;
	background-color: #000;
	right: -17px;
	top: 12px;
}

.react-tel-input .form-control,
.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus,
.react-tel-input .flag-dropdown.open .selected-flag {
	background: #000 !important;
}

/* -----Phone Input End ----- */
.drop_area{
  border: none !important;
  border-radius: 16px !important;
  background-color: black !important;
  width: 350px;
  height: 90px !important;
}


.dots-loading {
  position:absolute;
  width: 40px;
  aspect-ratio: 4;
  --_g: no-repeat radial-gradient(circle closest-side,rgb(255, 174, 87) 90%,#0000);
  background:
    var(--_g) 0%   50%,
    var(--_g) 50%  50%,
    var(--_g) 100% 50%;
  background-size: calc(100%/3) 100%;
  animation: d7 1s infinite linear;
}
@keyframes d7 {
    33%{background-size:calc(100%/3) 0%  ,calc(100%/3) 100%,calc(100%/3) 100%}
    50%{background-size:calc(100%/3) 100%,calc(100%/3) 0%  ,calc(100%/3) 100%}
    66%{background-size:calc(100%/3) 100%,calc(100%/3) 100%,calc(100%/3) 0%  }
}

.white-dots-loading-small {
  position:absolute;
  width: 36px;
  aspect-ratio: 5;
  --_g: no-repeat radial-gradient(circle closest-side,#fff 90%,#0000);
  background:
    var(--_g) 0%   50%,
    var(--_g) 50%  50%,
    var(--_g) 100% 50%;
  background-size: calc(100%/3) 100%;
  animation: d8 1s infinite linear;
}
@keyframes d8 {
    33%{background-size:calc(100%/3) 0%  ,calc(100%/3) 100%,calc(100%/3) 100%}
    50%{background-size:calc(100%/3) 100%,calc(100%/3) 0%  ,calc(100%/3) 100%}
    66%{background-size:calc(100%/3) 100%,calc(100%/3) 100%,calc(100%/3) 0%  }
}

.yellow-dots-loading-small {
  position:absolute;
  width: 36px;
  aspect-ratio: 5;
  --_g: no-repeat radial-gradient(circle closest-side,rgb(255, 174, 87) 90%,#0000);
  background:
    var(--_g) 0%   50%,
    var(--_g) 50%  50%,
    var(--_g) 100% 50%;
  background-size: calc(100%/3) 100%;
  animation: d8 1s infinite linear;
}
@keyframes d8 {
    33%{background-size:calc(100%/3) 0%  ,calc(100%/3) 100%,calc(100%/3) 100%}
    50%{background-size:calc(100%/3) 100%,calc(100%/3) 0%  ,calc(100%/3) 100%}
    66%{background-size:calc(100%/3) 100%,calc(100%/3) 100%,calc(100%/3) 0%  }
}

.gray-dots-loading-small {
  position:absolute;
  width: 25px;
  aspect-ratio: 5;
  --_g: no-repeat radial-gradient(circle closest-side,#ffffff 90%,#0000);
  background:
    var(--_g) 0%   50%,
    var(--_g) 50%  50%,
    var(--_g) 100% 50%;
  background-size: calc(100%/3) 100%;
  animation: d9 1s infinite linear;
}
@keyframes d9 {
    33%{background-size:calc(100%/3) 0%  ,calc(100%/3) 100%,calc(100%/3) 100%}
    50%{background-size:calc(100%/3) 100%,calc(100%/3) 0%  ,calc(100%/3) 100%}
    66%{background-size:calc(100%/3) 100%,calc(100%/3) 100%,calc(100%/3) 0%  }
}

#varification_processing_animation {
  position:absolute;
  top:12%;
  left:20%;
  animation: orbit 3s forwards infinite;
  animation-timing-function: linear;
}

@keyframes orbit {
    from {  -webkit-transform: rotate(0deg) translateX(30px) rotate(0deg); }
    to   {  -webkit-transform: rotate(360deg) translateX(30px) rotate(-360deg); }
}

/* Yellow Range Slider */
/* #range-slider-yellow {
   background: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23555' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E")
    #333;
    height: 0.2rem;
} */

#range-slider-yellow {
    height: 0.2rem;
}

#range-slider-yellow .range-slider__range {
  background: linear-gradient(90deg, rgba(221,128,73,1) 0%, rgba(210,159,78,1) 100%, rgba(0,212,255,1) 100%);
  transition: height 0.3s;
}

#range-slider-yellow .range-slider__thumb {
  background: linear-gradient(90deg, rgba(221,128,73,1) 0%, rgba(210,159,78,1) 100%, rgba(0,212,255,1) 100%);
  transition: transform 0.3s;
  height: 1.9rem;
  width: 1.9rem;
}

#range-slider-yellow .range-slider__thumb[data-active] {
  transform: translate(-50%, -50%) scale(1.25);
}

#range-slider-yellow .range-slider__range[data-active] {
  height: 2rem;
}

#filterModel{
  background-color: transparent !important;
  position: absolute !important;
}



.react-input-emoji--container {
	background-color: black !important;
	border: 2px solid #dcdcdc !important;
  color: white !important;
	border-radius: 30px !important;
}
.react-input-emoji--container:hover {
	border: 2px solid rgb(255, 174, 87) !important;
}
.react-input-emoji--input{
	height: 50px !important;
	padding: 13px 165px 13px 20px !important;
}

.rreact-input-emoji--input:focus .react-input-emoji--container  {
	border: 2px solid rgb(255, 174, 87) !important;
}

.react-input-emoji--button svg {
	fill: #6C757D !important;
}
.react-input-emoji--button__show svg {
	fill: #32cd32 !important;
}

.react-input-emoji--button{
	position: absolute !important;
	right:3.3rem;
	z-index:2;
}

@media screen and (min-width: 0px) and (max-width: 574px) {
	.react-input-emoji--input{
		padding: 13px 165px 13px 20px !important;
	}
	.react-input-emoji--button{
		/* bottom: -2.2rem; */
	}
}

.react-emoji-picker--wrapper {
	z-index: 1 !important;
}

.react-input-emoji--container {
	margin: 0 !important;
}



/* Range Slider as a Single Thumb Slider */
.single-thumb .range-slider__thumb[data-lower] {
  width: 0 !important;
}
.single-thumb .range-slider__range {
  border-radius: 6px !important;
}

/* Vertical Range Slider */
.vertical-slider[data-vertical] {
  height: 180px !important;
}

.carousel .slide img{
  padding: 0;
  width: 100%;
}

.wy-headline,.wy-message-meta{
  color: white !important;
}

.customGooglePlacesAutocomplete {
  .css-1p89gle-control {
    z-index: 2 !important;
  }

  .css-1wa3eu0-placeholder,
  .css-b8ldur-Input {
    position: relative;
    z-index: 2 !important;
  }

  .css-yk16xz-control,
  .css-1uccc91-singleValue {
    position: relative;
    z-index: 2 !important;
  }
}
.wy-avatar-header{
  color: white !important;
}

.wy-message-editor{
  background-color: rgba(27,29,42,255) !important;
}

.wy-appbar-text .wy-typing-hide{
  color: white !important;
}

.wy-open{
  background-color: black !important;
  border: 1px solid rgb(255, 174, 87) !important;
}

.wy-appbars > .wy-appbar{
  background: black !important;
}

.wy-appbars > .wy-appbar > .wy-button.wy-button-icon{
  background:black !important;
}

.wy-appbars > .wy-appbar > .wy-button.wy-button-icon > svg{
  color:white;
}

.cm-theme-light > .cm-editor{
  background:black !important;
  color: white !important;
}

.cm-theme-light > .cm-editor.cm-focused{
  background:black !important;
  border: 1px solid rgb(255, 174, 87) !important;
}

.wy-appbars > .wy-appbar .wy-appbar-text{
  color: rgb(255, 174, 87) !important;
}

.wy-dropdown-menu{
  background:black !important;
  border: 1px solid rgb(255, 174, 87) !important;
  color: white !important;
}

.wy-dropdown-item,.wy-item-body{
  color: white !important;
}

.wy-dropdown-item:hover{
  background: linear-gradient(90deg, rgb(221, 128, 73) 0%, rgb(210, 159, 78) 100%, rgb(0, 212, 255) 100%) !important;
}

.wy-dropdown-menu > .wy-dropdown-item:nth-child(3) {
  display: none !important;
}
.css-1nmdiq5-menu{
  z-index: 5 !important;
}
.css-1n6sfyn-MenuList{
  color: black !important;
}
.css-yubmro-control{
  /* background-color: rgb(255, 174, 87) !important; */
}