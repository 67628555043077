.carousel.carousel-slider{
    overflow: visible;
}


#profileCarousel .carousel .control-prev.control-arrow {
    left: 0;
    height: 50%;
    right: 0;
    cursor: -webkit-image-set(url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAABX0lEQVR4AcTVIagUQQAG4En3erveFFQw2LEs2MFqT4rZstG+Ww1Gg7xi3eaqKAY1rCCIT1QPy77d8MTTz3DCBA+ZnT3vPhjYTf8/M7szIRUWKFChQYfhz+jQoEKBRdgVLFGjl65HjeWc4COUGOUbUeIoZ9at3WmTVwMXcWL3TnAhZeb54Wkllv/a89b/1279JlDan3Lb0o/2Z0TcCtT2r44nHL0cp89ZHWMtQ49FQCHH1we053l8ju5mbokioJoRHkdeiSqgmRseS9yaWqIJ6KRaPYzhTy7F4KeX4/Pb2yboAgapXlzdhDy7wrdHMfTzfV5dj+/fv0g0TCvw6R5vbjC+5uxjDFwdsx54d4f3d01wGtDJ8FeBPF1Ac8ACTUB1wAJVQHHAAsWMo/gnL69tfsGzD9lH8bzL6NcP1uO8y+j3Br46HvgGycA3yejfKB34ZvnAd0zo3zUbKp1T+nfPAUL55aW+dbjUAAAAAElFTkSuQmCC) 1x,url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAChUlEQVR4AeTZAWQzZxyA8fsUQCkEoAAMNmRgZQwoYGQYGAnAGASACINBAsygAAFAgYFtIyqVdtVsmQ1bZluQiLTNdJqtzW/YWKrvouvXuzdJHl70NHgekrv//5K0QQ4FVNBAGz2MMP3njNBDGw1UUEAuWUWQRw0dzDydGTqoIb/s0tsooys9uihje5nEd1DFWHaMUcVOTPEXKGIoHkMU8SJr+V00LQ9N7GYlv4eB5WOAvbTlS5haXqYopSG+hbrVoY6t55Q/tHocPksE1K0u9ef4zq86pZf5tZ9afabYe8p9fmB9GGD3/zzhNa0fzUc9MaJofSk+ZrAZWl+GCwcoVK0/1UXz/Nj6Mw7uE1C2OZRDAbqiMBOBbmCHlzGXR5zt03qV7z/gz0sZk58PUJMl489pvcbRK/+e80LWEWrzATpR5ecj3F7JiM783n4WRz5wOplFmCGXoBBfPlqEQoJKbPlwhHeyiFBJ0IgvvyjCRIo0ErTjy0eL0E7QkwZXrf+WP3nz4bXTtzjOh///63dxJwV6CUbS4PztsMw37zH+7OH1bpHJCcevhz938YUUGCWYSoP2G2H5u9+5aoUDwOQ0HGH0qRSYphfgxw/D8iwOAJOv7kc42+fuOrUAI2kw+4P+Ad+9z6+f/P03jwsAN31++Zj+QZq3w1GCnqwJBxCBXoL2BgdoJ2hscIBGgsoGB6gkKGxwgEJ4HN6MADPkwguRzQjQCa/EogUoyZhavKXoTf9hgB8qMiYfdy3+00f3p8Obn2VIdzlejFx/y8WX3P62FC9G/mrfDgQAAAAABPlbD3Ic3zqNiaPyuEHCImOSsskZJa2yZmm7PDCBzEBT2Bw4ic7C0xc+H32aXNyvFhoCAAAAAElFTkSuQmCC) 2x) 16 16,auto;
    pointer-events: auto;
}
#profileCarousel .carousel .control-disabled.control-arrow{
    opacity: 1 !important;
    cursor: -webkit-image-set(url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAQAAADZc7J/AAAA00lEQVR42q2VvRGDMAxGVZkVvESyhvdiB3sdl8xAnSJHSUxD9VJwOQhgQpA/dTrrnWXrR5AdMzg8kZZEoiXicZi9s1uXJdCzp56APQZU1AwcaaCmygEsDWfULO8xh995cFYPbmuA/SN8QtgloDp5+e9EqhlQc0X1B2B/vHz+R+wECFxVQASTKZsz6jGCQyMneBXAC1EFiEK79o2kjI1bQCukta/jmbFuC0hawKtACupHVH+jupDUpaxupgLtrB4oBUaaeqgWGOsFFkuB1VZguV5Y72/wGRTLQ1h07gAAAABJRU5ErkJggg==) 1x,url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAQAAAAAYLlVAAABmElEQVR42s2Zu42DQBBAFzm2REJKE4SUQQXQAjmiAeiCAtyDMzIkYkLrEHJO4ncBOt3HPnv5eeZNaqEneXdmdsZgZoZHREZFTcfAyMhAR01FRoQ393tzfhxQ0HDjGTcaCoKtBY6ktMyhJeW4jYBLzpUlXMlx1wk4xPSsoSfGWSrgc2YLzvhLBEI+2IoPwrkCCSNbMpLYCxwo2YOSg43AgRN7cbpXuBco2ZPylUDC3iTPBMKNj97j4xj+J+BvePGeX0r/kYCzUdqxS03OvUDMO4n/Crgrc/78GuH+Fsh5N/lPgePCgruG69QvTAIpEqTfAq2IQPslECBFMAkUYgLFJNCICTQYg/ei0d6TG54hQpLIkIkKZIZKVKAy1KICtaGz6SF6LjOjt+ttOsNgU7ouC8KqvA7GRvSyMGwaNAUC4n+B+CEUv4biiUg8FYsXI/FyLN6QKGjJxJtS8bZcwcNE/Gkm/jhV8DwXH1AoGNGID6kUjOkUDCoVjGoVDKvFx/UKFhYqVjYKllYK1nYKFpcqVrcqltc7ru8/AYb7Xtl7BNfzAAAAAElFTkSuQmCC) 2x) 16 16,auto;
    display: block !important;
}

#profileCarousel .carousel .control-next.control-arrow{
    left: 0;
    top:auto;
    height: 50%;
    right: 0;
    bottom: 0;
     /* cursor: -webkit-image-set(url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAQAAADZc7J/AAAA00lEQVR42q2VvRGDMAxGVZkVvESyhvdiB3sdl8xAnSJHSUxD9VJwOQhgQpA/dTrrnWXrR5AdMzg8kZZEoiXicZi9s1uXJdCzp56APQZU1AwcaaCmygEsDWfULO8xh995cFYPbmuA/SN8QtgloDp5+e9EqhlQc0X1B2B/vHz+R+wECFxVQASTKZsz6jGCQyMneBXAC1EFiEK79o2kjI1bQCukta/jmbFuC0hawKtACupHVH+jupDUpaxupgLtrB4oBUaaeqgWGOsFFkuB1VZguV5Y72/wGRTLQ1h07gAAAABJRU5ErkJggg==) 1x,url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAQAAAAAYLlVAAABmElEQVR42s2Zu42DQBBAFzm2REJKE4SUQQXQAjmiAeiCAtyDMzIkYkLrEHJO4ncBOt3HPnv5eeZNaqEneXdmdsZgZoZHREZFTcfAyMhAR01FRoQ393tzfhxQ0HDjGTcaCoKtBY6ktMyhJeW4jYBLzpUlXMlx1wk4xPSsoSfGWSrgc2YLzvhLBEI+2IoPwrkCCSNbMpLYCxwo2YOSg43AgRN7cbpXuBco2ZPylUDC3iTPBMKNj97j4xj+J+BvePGeX0r/kYCzUdqxS03OvUDMO4n/Crgrc/78GuH+Fsh5N/lPgePCgruG69QvTAIpEqTfAq2IQPslECBFMAkUYgLFJNCICTQYg/ei0d6TG54hQpLIkIkKZIZKVKAy1KICtaGz6SF6LjOjt+ttOsNgU7ouC8KqvA7GRvSyMGwaNAUC4n+B+CEUv4biiUg8FYsXI/FyLN6QKGjJxJtS8bZcwcNE/Gkm/jhV8DwXH1AoGNGID6kUjOkUDCoVjGoVDKvFx/UKFhYqVjYKllYK1nYKFpcqVrcqltc7ru8/AYb7Xtl7BNfzAAAAAElFTkSuQmCC) 2x) 16 16,auto; */
     cursor: -webkit-image-set(url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAABYUlEQVR4AczVgQYUQRzH8QV3r3CvUFQKvUBYeoEAeoQSgGABAbALEAKEBAEs0HYpocJGpEvVCfZucWnrE+4Y6mRnXHd9GXbB7zvz/89/srFgghwlarRY71aLGiVyTLJDgRkqdMbTocIsPZgpCvTS6VFgmrLrxuFoRp8GzmPh8CxwbszOk8IjJGZ/q3nj39Ps7QkUjkex7+h7x6NHKAUqKfz8zpDsXYUJRyeaH7y4yvwSm/cS6DDJkEth84HHZ7Zr+VAieYbyhAJlhvqEAnWG9oQCbYa1sXy8y+vr9K/+FBh63t7m3R0RrOMEnl/ZBj69zNdHQeDTPV5eC//fPhvJKq4Eywc0Z7chTy6EwPnF8P3mlgja+Cb8cj9I/L7amxiim7AUSJdob2BIuoY5JEukh0OePoph9Wx3BYfkURweo+NT/T/P8a8NcINk4Jtk9G+UDnyzfOA7JvTvmg2Vzin9u+cAsVPlpiSloXwAAAAASUVORK5CYII=) 1x,url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAACn0lEQVR4AeSZAWQbYRiGrwqgFApQAAYbbmClDBhg3DAwEoAxCABRBoMWmMEAAoACA9tGVOfSTW+72bBlthWJkzaZTrMlefaRj6VyIkv7/3d39/Cg1+td35e7/P8XxzTAmuiJVbEm+mJTjMS+GukxX8+p6t+sOVkEcMVtMRBHLM5IDPRabtpDr4gVMcQcod5jJU3BV8UtsYM9OnrP1SSDL4klsU1ytPV/WLIdfl2skx7q4rqt8Btii/TREjdMhy+LfdJLXyybCL4s7pAddsTlywy/S/bYvZQStM2ssnPxZz77lC/ytu+TcTTDxiKf8y1ygmZZ/58VXp38UZ9rxQiUyC+leTY2bXKKZludVcAW+Wdr1n6+Q87RjCtxBVSwzekHOH4Fg59YphJXQIhNvj6CvStjG5tw9g2LhHEzPHucHWn4CT9XsYw7WcA2NunuTxcQlrHM9mQBQfIFlLBMMDm3HxWtAM285gAeSpEKUDwHqBa4gKoD1ApcQM0B/AIX4DtAs8AFNB0gwgSj33D0FD7ehx9Pxj/PXYAulr4/Hl9j0MUQkWNs7PXl4fmA7+/B8Nd8BfTewOvr/3739hYMTzFA31wB/g0NEFPCrAJ6B+fDq0TPjBUQYYLD29MhtAQ6z+ML6DViwqvHL409Ak1jL7r9a/FhGpvTxw5uSng3/vx3d4GhsZegjyk6L9ASFje4A4MehvCNL4S0hNSFV2rxS+HkS9DwXStLYQ8lNSUEnvHwimd1O6wlpCO8bodtD0Rml3BoJ7wSWB2JzS5Bw/85SWwk5mKbk73xEnf/Knx6YDW84lofi8czIgHC+C9GikPlb/t2IAAAAAAgyN96kOP4pDFxVB43SFhkTFI2OaOkVdYsbZcHJpAZaAqbAyfRWXh6yucDmK1c3LyFJbwAAAAASUVORK5CYII=) 2x) 16 16,auto
}

#profileCarousel .carousel .control-disabled.control-arrow{
    opacity: 1 !important;
    cursor: -webkit-image-set(url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAQAAADZc7J/AAAA00lEQVR42q2VvRGDMAxGVZkVvESyhvdiB3sdl8xAnSJHSUxD9VJwOQhgQpA/dTrrnWXrR5AdMzg8kZZEoiXicZi9s1uXJdCzp56APQZU1AwcaaCmygEsDWfULO8xh995cFYPbmuA/SN8QtgloDp5+e9EqhlQc0X1B2B/vHz+R+wECFxVQASTKZsz6jGCQyMneBXAC1EFiEK79o2kjI1bQCukta/jmbFuC0hawKtACupHVH+jupDUpaxupgLtrB4oBUaaeqgWGOsFFkuB1VZguV5Y72/wGRTLQ1h07gAAAABJRU5ErkJggg==) 1x,url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAQAAAAAYLlVAAABmElEQVR42s2Zu42DQBBAFzm2REJKE4SUQQXQAjmiAeiCAtyDMzIkYkLrEHJO4ncBOt3HPnv5eeZNaqEneXdmdsZgZoZHREZFTcfAyMhAR01FRoQ393tzfhxQ0HDjGTcaCoKtBY6ktMyhJeW4jYBLzpUlXMlx1wk4xPSsoSfGWSrgc2YLzvhLBEI+2IoPwrkCCSNbMpLYCxwo2YOSg43AgRN7cbpXuBco2ZPylUDC3iTPBMKNj97j4xj+J+BvePGeX0r/kYCzUdqxS03OvUDMO4n/Crgrc/78GuH+Fsh5N/lPgePCgruG69QvTAIpEqTfAq2IQPslECBFMAkUYgLFJNCICTQYg/ei0d6TG54hQpLIkIkKZIZKVKAy1KICtaGz6SF6LjOjt+ttOsNgU7ouC8KqvA7GRvSyMGwaNAUC4n+B+CEUv4biiUg8FYsXI/FyLN6QKGjJxJtS8bZcwcNE/Gkm/jhV8DwXH1AoGNGID6kUjOkUDCoVjGoVDKvFx/UKFhYqVjYKllYK1nYKFpcqVrcqltc7ru8/AYb7Xtl7BNfzAAAAAElFTkSuQmCC) 2x) 16 16,auto;
    display: block !important;
}

#profileCarousel .carousel.carousel-slider .control-arrow:hover{
    background: rgb(255 255 255 / 0%);
    z-index: 1;
}

#profileCarousel .carousel .control-arrow:before, .carousel.carousel-slider .control-arrow:before{
    display: none;
}

#profileCarousel .carousel .control-dots{
    display:flex;
    flex-direction: column;
    width:auto;
    right: -1rem;
    top: 0;
    bottom: auto;
    background-color: #ededed !important;
    width: 4px !important;
    border-radius: 5px !important;
    overflow: hidden;
    max-height: 6rem;

}
#profileCarousel .carousel .control-dots .dot{
    width: 4px !important;
    box-shadow: none;
    height: 2rem !important;
    background-color: #ededed !important;
    opacity:1;
    border-radius: 0;
    margin:0;
}
#profileCarousel  .carousel .control-dots .dot.selected{
    background-color: rgba(255,174,87,255)  !important;
    border-radius: 5px !important;
}


#profileCarousel .carousel .slider-wrapper.axis-vertical{
    /* height: calc(100vh - 170px); */
    height: calc(100vh - 225px) !important;
    border-radius: 24px;
    position: relative;
    z-index: 1;
}

@media screen and (min-width: 0px) and (max-width: 574px) {
    #profileCarousel .carousel .slider-wrapper.axis-vertical{
        height: calc(100vh - 275px) !important;
    }
}