nav {
    width: 100%;
    height: 72px;
    /* background-color: white; */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.switch input {
    position: absolute;
    opacity: 0;
}

.switch {
    display: inline-block;
    font-size: 20px;
    height: 0.7em;
    width: 2em;
    background: #BDB9A6;
    border-radius: 1em;
}

.switch div {
    height: 0.7em;
    width: 1em;
    border-radius: 1em;
    background: #FFF;
    box-shadow: 0 0.1em 0.3em rgba(0, 0, 0, 0.3);
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    transition: all 300ms;
}

.switch input:checked+div {
    -webkit-transform: translate3d(100%, 0, 0);
    -moz-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
}

.mdSize {

    color: #ffc629;
    font-size: 23px;
}

.highlight {
    text-align: center;
    border-radius: 20px;
    margin-top: 1.3rem;
    padding: .8rem;
    background: #FFF7D8 !important;
}

.filter {
    margin-top: 1.2rem;
    margin-left: 1rem;
}

label {

    cursor: pointer;
}


#language {
    border: none;
    outline: none;
}


#language option {

    margin-top: 1rem;
}

#language option:hover {

    background: green;
}