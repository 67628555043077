.nav-item:after {
    content: "";
    display: block;
    height: 3px;
    width: 0;
    background: transparent;
    transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after {
    /* width: 100%; */
    /* background: black; */
}

.nav-item.active {
    color: #ffdd40;
    border: 1px solid #ffdd40;
}

@media screen and (max-width: 960px) {

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        border-top: 1px solid #fff;
        position: absolute;
        top: 70px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
        /* height: 100vh; */
    }

    .nav-menu.active {
        background: #ffc629;
        left: 0px;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .nav-item .active {
        color: rgb(79, 6, 57);
        border: none;

    }

    .nav-links {
        color: black;
        width: 100%;
        display: table;
    }
}